import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from "../data/store"
import { useFestival } from "../hooks/festival"
import { useParams } from "react-router"
import { IFestFilters, IFestival } from "../data/interfaces"
import { useSearchParams } from "react-router-dom"
import PEFestProjectTerm from "./projectList/PEFestProjectTerm"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { Loading } from "@/libs/useful"
import AdCard from "@/modules/pe-topos-module/views/ads/AdCard"

/* Protopia Ecosystem component */
export interface IAdListFormProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
const AdListForm = (props: IAdListFormProps): JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters )
    
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const [offset, setOffset] = useState<number>(0)  
    const [fullCount, setFullCount] = useState<number>(0)
    const over = ``
    const [paging, setPaging] = useState<string>( "" )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const updateURLSearchParams = () => {
        let params: any = {}
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
        }
        SetURLSearchParams( params )
    }
    useEffect(()=> {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [])
    useEffect(() => {
        setOffset(0) 
        updateURLSearchParams()
        
    }, [ filters ])

    const isLoading = useFestival(!props.parentMode, festId) 

    if(isLoading ) return <Loading /> 

    return <div className="mx-auto w-100">
        <PEFestProjectTerm />
        <div className='mx-auto container p-0'>
            <Feed 
                offset={0}
                data_type={"Ad"} 
                land_id={ festId } 
                class_name=' '
                containerClassName="" 
                component={ AdCard }
                params={{ 
                    col: 3
                }}
            />
        </div>
    </div>
}
export default AdListForm